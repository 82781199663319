import vrt from '../assets/img/game/2/d2.jpg'
import kuhinja from '../assets/img/game/2/k2.jpg'
import outItem1 from '../assets/img/game/2/vrt/1.png'
import outItem2 from '../assets/img/game/2/vrt/2.png'
import outItem3 from '../assets/img/game/2/vrt/3.png'
import outItem4 from '../assets/img/game/2/vrt/4.png'
import outItem5 from '../assets/img/game/2/vrt/5.png'
import p1 from '../assets/img/game/2/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game/2/kuhinja/3D_MALI_Seceri_CIMET.png'
import k2 from '../assets/img/game/2/kuhinja/Aroma_Badem_2411 (1).png'
import k3 from '../assets/img/game/2/kuhinja/Modlice za kekse u obliku zeca i mrkve.png'
import k4 from '../assets/img/game/2/kuhinja/posipač za brašno-1.png'
import k5 from '../assets/img/game/2/kuhinja/Slatko cvijeće na balkonu.png'

const day_2 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "216.8deg", pitch: "-22.7deg" },
                size: { width: 20, height: 24 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-42.5deg", pitch: "-10.3deg" },
                size: { width: 16, height: 21 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-68.2deg", pitch: "-14.2deg" },
                size: { width: 17, height: 22 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "146.3deg", pitch: "-41deg" },
                size: { width: 17, height: 22 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "38deg", pitch: "-9.2deg" },
                size: { width: 19, height: 23 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-32.8deg", pitch: "-8.2deg" },
                size: { width: 65, height: 72 },
                product_title: 'Cimet šećer',
                product_description: 'Dr. Oetker Cimet šećer savršen je dodatak za sve slastice koji će vam ispuniti dom finim mirisom cimeta i svježe pripremljenih kolača.',
                product_image: k1,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/aroma-seceri/cimet-secer'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "231.2deg", pitch: "-34deg" },
                size: { width: 32, height: 60 },
                product_title: 'Tekuća aroma za hranu – badem',
                product_description: 'Dr. Oetker tekuća aroma slatkog badema nezaobilazna je u aromatiziranju biskvita, krema, napitaka, sladoleda, mješavina i ostalih slastica.',
                product_image: k2,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/arome/tekuca-aroma-badem'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "172.5deg", pitch: "-51.5deg" },
                size: { width: 120, height: 115 },
                product_title: 'Modlice za kekse u obliku zeca i mrkve',
                product_description: 'S prekrasnim setom modlica u obliku zeca i mrkve u tren oka možete dočarati izvrsne uskršnje kekse. Jednostavno pripremite ukusno tijesto za kekse, izrežite i nakon pečenja maštovito ukrasite.',
                product_image: k3,
                product_url: 'https://oetker-shop.hr/proizvod/modlice-za-kekse-u-obliku-zeca-i-mrkve/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-15.8deg", pitch: "-32.2deg" },
                size: { width: 95, height: 95 },
                product_title: 'Posipač za brašno 350g',
                product_description: 'Jednoručni posipač za brašno promjera oko 10 cm zadivljuje svojom lakoćom rukovanja! Jednostavno uspite željeni suhi sastojak i pomoću jedne ruke prosijte sadržaj.',
                product_image: k4,
                product_url: 'https://oetker-shop.hr/proizvod/posipac-za-brasno-350g/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "260.2deg", pitch: "-30.7deg" },
                size: { width: 210, height: 160 },
                product_title: 'Slatko cvijeće na balkonu',
                product_description: 'Isprobajte recept za ove slatke muffine sa upečatljivom dekoracijom',
                product_image: k5,
                product_url: 'https://www.oetker.hr/recepti/r/slatko-cvijece-na-balkonu'
            }
        ]
    },
]

export default day_2