import vrt from '../assets/img/game/7/d7.jpg'
import kuhinja from '../assets/img/game/7/k7.jpg'
import vrataVrt from '../assets/img/game/vrt-vrata.png'
import outItem1 from '../assets/img/game/7/vrt/1.png'
import outItem2 from '../assets/img/game/7/vrt/2.png'
import outItem3 from '../assets/img/game/7/vrt/3.png'
import outItem4 from '../assets/img/game/7/vrt/4.png'
import outItem5 from '../assets/img/game/7/vrt/5.png'
import p1 from '../assets/img/game/7/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game/7/kuhinja/5907707051067-Aroma_Rum_2411-1.png'
import k2 from '../assets/img/game/7/kuhinja/drOetker-Finesse-limun-1200x1200.png'
import k3 from '../assets/img/game/7/kuhinja/prijateljice.png'
import k4 from '../assets/img/game/7/kuhinja/170000817-Modeli-za-saumrole-4kom-1.png'
import k5 from '../assets/img/game/7/kuhinja/recept2.png'
import vrataKuhinja from '../assets/img/game/kuhinja-vrata.png'

const day_7 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "106.5deg", pitch: "-44.7deg" },
                size: { width: 24, height: 30 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "34.8deg", pitch: "-8.2deg" },
                size: { width: 15, height: 18 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-83.2deg", pitch: "-12.4deg" },
                size: { width: 19, height: 24 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-26deg", pitch: "-33.6deg" },
                size: { width: 20, height: 26 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-107deg", pitch: "-38.3deg" },
                size: { width: 20, height: 26 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-87.2deg", pitch: "-24.5deg" },
                size: { width: 30, height: 80 },
                product_title: 'Aroma ruma u bočici',
                product_description: 'Dr. Oetker aroma ruma praktična je za aromatiziranje najfinijih deserata, napitaka, tijesta i nadjeva.',
                product_image: k1,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/arome/aroma-ruma-u-bocici'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-54.2deg", pitch: "7.6deg" },
                size: { width: 45, height: 55 },
                product_title: 'Finesse naribana korica limuna',
                product_description: 'Dr. Oetker Finesse proizvodi se iz prirodne korice limuna i atraktivna je zamjena za uporabu ribane korice limunove voćke u receptima.',
                product_image: k2,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/arome/finesse-naribana-korica-limuna'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-128.4deg", pitch: "-38.5deg" },
                size: { width: 370, height: 250 },
                product_title: 'Punjena kutija ‘Prijateljice’',
                product_description: 'Predivan i trajan dar u obliku Punjene kutije “Prijateljice” sigurno će razveseliti svakog ljubitelja pečenja slastica! Set sadrži elegantnu Vintage dozu s pretincima – reljefnog dizajna u kombinaciji bijele i ružičaste boje s nekim od neizostavnih Dr. Oetker dodataka za pečenje, aroma i dekora.',
                product_image: k3,
                product_url: 'https://oetker-shop.hr/proizvod/punjena-kutija-prijateljice/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "163.6deg", pitch: "-54.3deg" },
                size: { width: 130, height: 210 },
                product_title: 'Modeli za šaumrole, 4kom',
                product_description: 'Zahvaljujući protivljepljivoj površini, šaumrole od lisnatog tijesta se lako odvajaju od modela i mogu se puniti i ukrašavati prema vlastitim željama: bilo kremom, šlagom, svježim sirom, raguom ili francuskom salatom',
                product_image: k4,
                product_url: 'https://oetker-shop.hr/proizvod/modeli-za-saumrole-4kom/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-27.3deg", pitch: "-37.9deg" },
                size: { width: 233, height: 100 },
                product_title: 'Uskrsni kolačići',
                product_description: 'Ovi slatki američki keksi od ukusnog miješanog tijesta, ukrašeni poput pilića, pravi su mamac za poglede na svečanom uskrsnom stolu.',
                product_image: k5,
                product_url: 'https://www.oetker.hr/hr-hr/recept/r/uskrsni-kolacici'
            },
        ]
    },
]

export default day_7