import vrt from '../assets/img/game/12/d12.jpg'
import kuhinja from '../assets/img/game/12/k12.jpg'
import outItem1 from '../assets/img/game/12/vrt/1.png'
import outItem2 from '../assets/img/game/12/vrt/2.png'
import outItem3 from '../assets/img/game/12/vrt/3.png'
import outItem4 from '../assets/img/game/12/vrt/4.png'
import outItem5 from '../assets/img/game/12/vrt/5.png'
import p1 from '../assets/img/game/12/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game/12/kuhinja/172011161-Kremfix.png'
import k2 from '../assets/img/game/12/kuhinja/Aroma_Badem_2411 (1).png'
import k3 from '../assets/img/game/12/kuhinja/4044935018705-Zeko kalup na zatvaranje-4.png'
import k4 from '../assets/img/game/12/kuhinja/alupi za puding s poklopcem.png'
import k5 from '../assets/img/game/12/kuhinja/recept12.png'

const day_12 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "111.2deg", pitch: "-32.6deg" },
                size: { width: 26, height: 30 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-124.5deg", pitch: "-18.7deg" },
                size: { width: 20, height: 24 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-104.8deg", pitch: "-30.3deg" },
                size: { width: 17, height: 22 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "67deg", pitch: "-30deg" },
                size: { width: 22, height: 26 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-43.2deg", pitch: "-10.8deg" },
                size: { width: 17, height: 24 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-34.8deg", pitch: "10.3deg" },
                size: { width: 75, height: 105 },
                product_title: 'Kremfix',
                product_description: 'Dr. Oetker Kremfix omogućava da torte imaju primamljiv izgled i konzistenciju, a tučenom šlagu daje čvrstoću.',
                product_image: k1,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/kremfix'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-17.8deg", pitch: "-31deg" },
                size: { width: 50, height: 100 },
                product_title: 'Tekuća aroma za hranu – badem',
                product_description: 'Dr. Oetker tekuća aroma slatkog badema nezaobilazna je u aromatiziranju biskvita, krema, napitaka, sladoleda, mješavina i ostalih slastica.',
                product_image: k2,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/arome/tekuca-aroma-badem'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-102.5deg", pitch: "-31.8deg" },
                size: { width: 214, height: 215 },
                product_title: 'Zeko kalup na zatvaranje, zlatni',
                product_description: 'Pravi klasik za Uskrs! Uz ovaj Dr. Oetker kalup zlatne boje ispecite kolač u obliku slatkog zečića, a neprianjajući premaz će vam omogućiti lako uklanjanje iz kalupa.',
                product_image: k3,
                product_url: 'https://oetker-shop.hr/proizvod/zeko-kalup-na-zatvaranje-zlatni/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-35.8deg", pitch: "-36deg" },
                size: { width: 207, height: 140 },
                product_title: 'Kalupi za puding s poklopcem 6×150 ml',
                product_description: 'Set od 6 malih kalupa idealan je za serviranje Dr. Oetker pudinga i ostalih slastica. Kalupi nježnih pastelnih boja (ružičaste, svijetlo zelene i svijetlo plave) savršeno će se uklopiti u svaku kuhinju. Izrađeni su od visokokvalitetne plastike, promjera oko 7,5 cm i kapaciteta do 150 ml. Veliki poklopac služi za zaštitu aroma, a mali za otpuštanje vakuuma i lakše odvajanje od kalupa prilikom posluživanja.',
                product_image: k4,
                product_url: 'https://oetker-shop.hr/proizvod/kalupi-za-puding-s-poklopcem-6x150-ml/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-196.5deg", pitch: "-55.2deg" },
                size: { width: 240, height: 230 },
                product_title: 'Uskršnji kolač',
                product_description: 'Isprobajte ruski čupavi kolač iz lima s uskrsnim dekoracijama',
                product_image: k5,
                product_url: 'https://www.oetker.hr/hr-hr/recept/r/uskrsnji-kolac'
            },
        ]
    },
]

export default day_12