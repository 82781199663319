import vrt from '../assets/img/game/8/d8.jpg'
import kuhinja from '../assets/img/game/8/k8.jpg'
import outItem1 from '../assets/img/game/8/vrt/1.png'
import outItem2 from '../assets/img/game/8/vrt/2.png'
import outItem3 from '../assets/img/game/8/vrt/3.png'
import outItem4 from '../assets/img/game/8/vrt/4.png'
import outItem5 from '../assets/img/game/8/vrt/5.png'
import p1 from '../assets/img/game/8/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game/8/kuhinja/400521000349-drOetker-Kvasac-instant-1.png'
import k2 from '../assets/img/game/8/kuhinja/5997381343664-drOetker-Gustin-200g-1.png'
import k3 from '../assets/img/game/8/kuhinja/Kalup za mini kuglofe.png'
import k4 from '../assets/img/game/8/kuhinja/Janje kalup na zatvaranje zlatni.png'
import k5 from '../assets/img/game/8/kuhinja/recept14.png'

const day_2 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-72.5deg", pitch: "-27.9deg" },
                size: { width: 19, height: 23 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-106deg", pitch: "-27.5deg" },
                size: { width: 16, height: 21 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "50.5deg", pitch: "-6.5deg" },
                size: { width: 13, height: 20 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-23.8deg", pitch: "-51.5deg" },
                size: { width: 16, height: 30 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "222.95deg", pitch: "-42deg" },
                size: { width: 23, height: 30 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-66.2deg", pitch: "-20.8deg" },
                size: { width: 55, height: 80 },
                product_title: 'Kvasac',
                product_description: 'Dr. Oetker Kvasac idealan je suhi kvasac za pripremu slatkih i slanih dizanih tijesta svih vrsta.',
                product_image: k1,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/kvasac/instant-kvasac'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "246.2deg", pitch: "-30.5deg" },
                size: { width: 115, height: 150 },
                product_title: 'Gustin fini jestivi škrob',
                product_description: 'Koristite Dr. Oetker Gustin fini jestivi škrob kako bi vaše tijesto bilo sočnije, a krem juhe i umaci još gušći.',
                product_image: k2,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/gustin/gustin-fini-jestivi-srkob'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "159.8deg", pitch: "-57deg" },
                size: { width: 226, height: 184 },
                product_title: 'Kalup za mini kuglofe',
                product_description: 'Kalup je dizajniran za izradu 6 mini kuglofa, svaki s gornjim promjerom od 6,5 cm i donjim promjerom od 5 cm, a savršena forma je osigurana zahvaljujući izvrsnim svojstvima protiv lijepljenja.',
                product_image: k3,
                product_url: 'https://oetker-shop.hr/proizvod/kalup-za-mini-kuglofe/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-28.2deg", pitch: "-34.2deg" },
                size: { width: 226, height: 184 },
                product_title: 'Janje kalup na zatvaranje, zlatni – 600 ml',
                product_description: 'Pravi klasik za Uskrs! Uz Dr. Oetker kalup zlatne boje ispecite kolač u obliku slatkog janjeta, a neprianjajući premaz će vam omogućiti lako uklanjanje iz kalupa.',
                product_image: k4,
                product_url: 'https://oetker-shop.hr/proizvod/janje-kalup-na-zatvaranje-zlatni-600-ml/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "14.8deg", pitch: "-19.3deg" },
                size: { width: 130, height: 93 },
                product_title: 'Kolač od mrkve',
                product_description: 'Pripremite ovaj sočan kolač od mrkve koji j e jednostavan za pripremu i vrlo ukusan.',
                product_image: k5,
                product_url: 'https://www.oetker.hr/hr-hr/recept/r/kolac-od-mrkve'
            },
        ]
    },
]

export default day_2