import vrt from '../assets/img/game/6/d6.jpg'
import kuhinja from '../assets/img/game/6/k6.jpg'
import vrataVrt from '../assets/img/game/vrt-vrata.png'
import outItem1 from '../assets/img/game/6/vrt/1.png'
import outItem2 from '../assets/img/game/6/vrt/2.png'
import outItem3 from '../assets/img/game/6/vrt/3.png'
import outItem4 from '../assets/img/game/6/vrt/4.png'
import outItem5 from '../assets/img/game/6/vrt/5.png'
import p1 from '../assets/img/game/6/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game/6/kuhinja/original-backin-prasak-za-pecivo-2019@2x.webp'
import k2 from '../assets/img/game/6/kuhinja/van.jpg'
import k3 from '../assets/img/game/6/kuhinja/Modlice Zečja obitelj-6.png'
import k4 from '../assets/img/game/6/kuhinja/170000818-Pekac-za-biskvit-pravokutni-1.png'
import k5 from '../assets/img/game/6/kuhinja/Uskrsni kuglof sa svježim sirom.png'
import vrataKuhinja from '../assets/img/game/kuhinja-vrata.png'

const day_6 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "60.7deg", pitch: "-19.6deg" },
                size: { width: 24, height: 28 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "87deg", pitch: "-30deg" },
                size: { width: 24, height: 28 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-124deg", pitch: "-18.3deg" },
                size: { width: 19, height: 25 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-79.3deg", pitch: "-27.7deg" },
                size: { width: 19, height: 24 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-133.6deg", pitch: "-48.5deg" },
                size: { width: 25, height: 29 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "14.6deg", pitch: "-18.5deg" },
                size: { width: 35, height: 45 },
                product_title: 'Original Backin prašak za pecivo',
                product_description: 'Bakin recept i Dr. Oetker Prašak za pecivo savršena su kombinacija za najfinija peciva i kolače.',
                product_image: k1,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/original-backin-u-vrecici'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-152.8deg", pitch: "-7.3deg" },
                size: { width: 70, height: 115 },
                product_title: 'Creative puding vanilija kardamom',
                product_description: 'Pripremite se za neočekivani spoj dva uzbudljiva okusa – vanilija i kardamom! Creative pudinzi osmišljeni su jedinstvenom kombinacijom dvaju suprotnih okusa koji će vas očarati svježinom uz dobro poznatu teksturu pudinga.',
                product_image: k2,
                product_url: 'https://oetker-shop.hr/proizvod/creative-puding-vanilija-kardamom/'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-68deg", pitch: "-30.5deg" },
                size: { width: 120, height: 115 },
                product_title: 'Modlice za kekse Zečja obitelj – 3 kom',
                product_description: 'Ovog Uskrsa pomoću modlica “Zečja obitelj” napravite najkreativnije keksiće za obitelj i prijatelje! Keksiće možete izrezati modlicama u tri različite veličine i ukrasiti ih po želji.',
                product_image: k3,
                product_url: 'https://oetker-shop.hr/proizvod/modlice-za-kekse-zecja-obitelj-3-kom/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-125deg", pitch: "-42.8deg" },
                size: { width: 220, height: 230 },
                product_title: 'Pekač za biskvit pravokutni 30cm',
                product_description: 'Savršen za biskvit i sve vrste kolača, opružni kalup za tortu sa zaštitom od curenja uskoro će postati neizostavni dio vaše kuhinje! Ovaj kalup za pečenje ne samo da privlači pažnju svojim sjajnim dvobojnim dizajnom, već i impresionira s izvrsnim neprijanjajućim premazom koji osigurava lako vađenje kolača.',
                product_image: k4,
                product_url: 'https://oetker-shop.hr/proizvod/pekac-za-biskvit-pravokutni-30cm/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-196deg", pitch: "-49.6deg" },
                size: { width: 240, height: 220 },
                product_title: 'Uskrsni kuglof sa svježim sirom',
                product_description: 'Oduševite svoje goste ovim sočnim kuglofom sa svježim sirom i šećernom glazurom.',
                product_image: k5,
                product_url: 'https://www.oetker.hr/recepti/r/uskrsni-kuglof-sa-svjezim-sirom'
            },

        ]
    },
]

export default day_6