import vrt from '../assets/img/game/3/d3.jpg'
import kuhinja from '../assets/img/game/3/k3.jpg'
import outItem1 from '../assets/img/game/3/vrt/1.png'
import outItem2 from '../assets/img/game/3/vrt/2.png'
import outItem3 from '../assets/img/game/3/vrt/3.png'
import outItem4 from '../assets/img/game/3/vrt/4.png'
import outItem5 from '../assets/img/game/3/vrt/5.png'
import p1 from '../assets/img/game/3/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game/3/kuhinja/3D_MALI_Seceri_LIMUN.png'
import k2 from '../assets/img/game/3/kuhinja/172011161-Kremfix.png'
import k3 from '../assets/img/game/3/kuhinja/170000614-Pekac-za-klasicno-poticosarkelj-klasicni-22-cm.png'
import k4 from '../assets/img/game/3/kuhinja/Modlice za kekse u obliku jajeta s motivima zeca i tulipana.png'
import k5 from '../assets/img/game/3/kuhinja/recept4.png'
import inItem1 from '../assets/img/game/3/kuhinja/old/finesse.jpeg'

const day_3 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-72.0deg", pitch: "-14.2deg" },
                size: { width: 15, height: 23 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "199.0deg", pitch: "-8deg" },
                size: { width: 21, height: 22 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-106.2deg", pitch: "-39deg" },
                size: { width: 18, height: 22 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "24.3deg", pitch: "-24.4deg" },
                size: { width: 15, height: 23 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-37deg", pitch: "-27.5deg" },
                size: { width: 15, height: 22 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-6.8deg", pitch: "-13.5deg" },
                size: { width: 35, height: 45 },
                product_title: 'Limun šećer',
                product_description: 'Dr. Oeker Limun zahvaljujući slatko-kiseloj kombinaciji vašim kolačima daje posebnu aromu.',
                product_image: k1,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/aroma-seceri/limun-secer'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-169deg", pitch: "-6.5deg" },
                size: { width: 45, height: 75 },
                product_title: 'Kremfix',
                product_description: 'Dr. Oetker Kremfix omogućava da torte imaju primamljiv izgled i konzistenciju, a tučenom šlagu daje čvrstoću.',
                product_image: k2,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/kremfix'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-56deg", pitch: "-21.8deg" },
                size: { width: 150, height: 118 },
                product_title: 'Kalup za kuglof, klasični 22 cm',
                product_description: 'Izrada kuglofa nikad nije bila jednostavnija!  Dr. Oetker kalup osigurava ravnomjerno pečenje  i spriječava lijepljenje tijesta uz rubove kalupa.',
                product_image: k3,
                product_url: 'https://oetker-shop.hr/proizvod/kalup-za-kuglof-klasicni-22-cm/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-28deg", pitch: "-38.3deg" },
                size: { width: 120, height: 115 },
                product_title: 'Modlice za kekse u obliku jajeta s motivima zeca i tulipana',
                product_description: 'Unesite dašak proljeća u vaš dom! Ovog Uskrsa pomoću modlica u obliku jajeta s motivom zečića i tulipana napravite najkreativnije keksiće za obitelj i prijatelje.',
                product_image: k4,
                product_url: 'https://oetker-shop.hr/proizvod/modlice-za-kekse-u-obliku-jajeta-s-motivima-zeca-i-tulipana/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "11deg", pitch: "-42.3deg" },
                size: { width: 219, height: 145 },
                product_title: 'Keksi u obliku jaja',
                product_description: 'Vrlo jednostavno pripremite kekse od prhkog tijesta koji su nadjeveni marmeladom od marelica za Uskrs',
                product_image: k5,
                product_url: 'https://www.oetker.hr/hr-hr/recept/r/keksi-u-obliku-jaja'
            }
        ]
    },
]

export default day_3