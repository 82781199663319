import vrt from '../assets/img/game/5/d5.jpg'
import kuhinja from '../assets/img/game/5/k5.jpg'
import vrataVrt from '../assets/img/game/vrt-vrata.png'
import outItem1 from '../assets/img/game/5/vrt/1.png'
import outItem2 from '../assets/img/game/5/vrt/2.png'
import outItem3 from '../assets/img/game/5/vrt/3.png'
import outItem4 from '../assets/img/game/5/vrt/4.png'
import outItem5 from '../assets/img/game/5/vrt/5.png'
import p1 from '../assets/img/game/5/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game/5/kuhinja/Aroma_Vanilija_2411 (1).png'
import k2 from '../assets/img/game/5/kuhinja/5997381346917-drOetker-Kakao-za-kolace-1.png'
import k3 from '../assets/img/game/5/kuhinja/kutija-nostalgija-2.png'
import k4 from '../assets/img/game/5/kuhinja/Posuda za topljenje čokolade-1.png'
import k5 from '../assets/img/game/5/kuhinja/recept11.png'

const day_5 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "117.5deg", pitch: "-16deg" },
                size: { width: 20, height: 26 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "22.4deg", pitch: "-15deg" },
                size: { width: 18, height: 24 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-46deg", pitch: "-16.7deg" },
                size: { width: 16, height: 22 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-110.3deg", pitch: "-20.7deg" },
                size: { width: 22, height: 26 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-68deg", pitch: "-14.4deg" },
                size: { width: 20, height: 24 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-33.5deg", pitch: "11deg" },
                size: { width: 37, height: 80 },
                product_title: 'Aroma vanilije u bočici',
                product_description: 'S Dr. Oetker aromom vanilije vaši će kolači i deserti jednostavno postati zavodljiviji.',
                product_image: k1,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/arome/aroma-vanilije-u-bocici'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-68deg", pitch: "-20.8deg" },
                size: { width: 75, height: 92 },
                product_title: 'Kakao za kolače',
                product_description: 'Dr. Oetker Kakao za kolače svojom punoćom i intentizvnom tamnom bojom, savršen je dodatak čokoladnim tijestima i kremama za kolače kao i za dekoraciju.',
                product_image: k2,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/kakao-za-kolace/kakao-za-kolace'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-102.8deg", pitch: "-32.5deg" },
                size: { width: 255, height: 180 },
                product_title: 'Kutija za kekse Nostalgija',
                product_description: 'Povijesni motiv iz 1890-ih čini ovu kutiju za kekse veoma posebnom. Kutija je raskošno ukrašena reljefom sa svih strana, uključujući i poklopac. Namjenjena je za čuvanje domaćih keksa, keksića, medenjaka i ostalih slatkih delicija, a u njoj također možete čuvati Dr. Oetker pudinge, dekore, slatke obroke, dodatke za kolače ili osobne predmete.',
                product_image: k3,
                product_url: 'https://oetker-shop.hr/proizvod/kutija-za-kekse-nostalgija/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-30.2deg", pitch: "-36deg" },
                size: { width: 120, height: 90 },
                product_title: 'Posuda za topljenje čokolade, nehrđajući čelik',
                product_description: 'Posuda za topljenje čokolade izrađena je od visokokvalitetnog nehrđajućeg čelika s posebno dugačkom ružkom za lakše rukovanje. Ovo kuhinjsko pomagalo idealno je za otapanje čokolade i kuverture.',
                product_image: k4,
                product_url: 'https://oetker-shop.hr/proizvod/posuda-za-topljenje-cokolade-nehrdajuci-celik/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-198.2deg", pitch: "-55.5deg" },
                size: { width: 253, height: 250 },
                product_title: 'Uskršnje ovčice',
                product_description: 'Iskušajte se u pravljenju ovih uskršnjih ovčica od dizanog tijesta koje su iznimno prozračne i izvrsne za pripremu za Uskrs zajedno s djecom.',
                product_image: k5,
                product_url: 'https://www.oetker.hr/hr-hr/recept/r/uskrsnje-ovcice'
            }
        ]
    },
]

export default day_5